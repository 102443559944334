@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Renk Paleti

.home-container {
    width: 'auto';
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    padding-left: 15px;
}

.card-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.card {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: auto;
    margin: 15px;
    margin-top: 35px;
    border-radius: 10px;
    padding: 18px;
    box-shadow: 0px 8px 10px 0px var(--card-shadow); // Kart Gölgesi
    background-color: var(--card-background); // Kart Arka Plan Rengi
}

.user-info {
    display: flex;
    flex-direction: column;
    background-color: var(--card-background); // Kart Arka Plan Rengi
    width: 46%;
    height: auto;
    margin: 15px;
    border-radius: 10px;
    justify-content: center;
    padding: 20px;
    gap: 10px;
    box-shadow: 0px 8px 10px 0px var(--card-shadow); // Kart Gölgesi
    word-wrap: break-word;
}

.recent-activities {
    display: flex;
    flex-direction: column;
    background-color: var(--card-background); // Kart Arka Plan Rengi
    width: 46%;
    height: auto;
    margin: 15px;
    border-radius: 10px;
    padding: 20px;
    gap: 10px;
    box-shadow: 0px 8px 10px 0px var(--card-shadow); // Kart Gölgesi
}

.recent-activities span:first-child {
    font-weight: 700;
    color: var(--text-light); // Açık Metin Rengi
}

.userinfo-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.card span:first-child {
    font-size: 1em;
    color: cadetblue;
    font-weight: 500;
}

.card span {
    font-weight: 600;
}

.wallet-usage-bar-chart {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
        margin-bottom: 15px;
    }
}
