@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



.register-container {
    display: flex;
    height: 100vh;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    background: url("../../assets/icon.png") repeat;
    background-size: 15px; // Size of the icon
    background-color: rgba(184, 194, 227, 0.23); 

    .circle {
        position: absolute;
        border-radius: 50%;
        background: var(--color-circle-primary);
        opacity: 1;
        animation: pulse 6s infinite ease-in-out;
      
        &:nth-child(1) {
          width: 300px;
          height: 300px;
          top: 69%;
          left: -5%;
          background: var(--color-circle-primary);
          animation: pulse 8s infinite ease-in-out;
        }
      
        &:nth-child(2) {
          width: 150px;
          height: 150px;
          top: 1%;
          left: 25%;
          background: linear-gradient(
            135deg,
            #83259f 0%,
            #0344a4 50%,
            #00096c 100%
          );
          animation:  pulse 8s infinite ease-in-out;
        }
      
        &:nth-child(3) {
          width: 150px;
          height: 150px;
          top: 6%;
          right: 15%;
          background: var(--color-circle-primary);
          animation:  pulse 8s infinite ease-in-out;
        }
      
        &:nth-child(4) {
          width: 250px;
          height: 250px;
          top: 10%;
          right: 1%;
          background: linear-gradient(
            90deg,
            #000367 0%,
            #0344a4 50%,
            #83259f 100%
          );
          animation: pulse 8s infinite ease-in-out;
        }
      
        &:nth-child(5) {
          width: 90px;
          height: 90px;
          bottom: 6%;
          right: 27%;
          background: var(--color-circle-primary);
          animation:  pulse 8s infinite ease-in-out;
        }
      }
      
      /* Animation Keyframes */
      @keyframes pulse {
        0%, 100% {
          transform: scale(1);
          opacity: 1;
        }
        50% {
          transform: scale(1.1);
          opacity: 0.8;
        }
      }
      
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      
      @keyframes moveUpDown {
        0%, 100% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-20px);
        }
      }
      
      @keyframes scale {
        0%, 100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.2);
        }
      }
      
    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px;
        width: 24%;
        background-color: #fafbff;
        border-radius: 20px;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);

        h2 {
            color: var(--text-dark); // Koyu Metin Rengi
            font-weight: 500;
            font-family: 'Poppins', sans-serif;
        }

        span {
            color: var(--text-dark); // Koyu Metin Rengi
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
            margin-bottom: 3vh;
            text-align: center;
        }
    }
}

form {
    width: 100%;
    margin-top: 0vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; 
}

.signup-form .row {
    display: flex;
    gap: 20px;
    width: 100%;
}

.signup-form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
}

.signup-form button {
    width: 100%;
    padding: 10px;
    background-color: var(--primary-color); // Ana Renk
    color: #fff;
    font-weight: 700;
    border: 0;
    border-radius: 4px;
    margin-top: 2vh;
    font-family: 'Poppins', sans-serif;
    &:hover {
        background-color: var(--primary-hover); // Hover Rengi
    }
}

.p {
    text-align: center;
    margin-top: 1vh;
    font-family: 'Poppins', sans-serif;

    p {
        margin-bottom: -4px;
        font-size: 0.8em;
        color: #000000;

        a {
            color: var(--primary-color); // Ana Renk
            text-decoration: underline;
        }
    }

    p:nth-child(2) {
        color: var(--text-light); // Açık Metin Rengi

        a {
            color: var(--primary-color); // Ana Renk
            text-decoration: underline;
        }
    }

    p:nth-child(3) {
        color: var(--light-blue); // Soğuk Mavi
    }
}

.footer {
    width: 100%;
    margin-top: 7vh;
}

.divider {
    width: 100%;
    border: 0.5px solid var(--divider-color); // Gri Tonu
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.rezerved {
    display: flex;
    justify-content: center;
    font-size: 0.7em;
    color: var(--reserved-color); // Açık Gri

    a {
        color: var(--link-color); // Link Rengi
        text-decoration: none;
        margin-right: 5px;
    }
}
