.mybankacc-container{
    padding: 2%;
    padding-top: 4%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .banksa{
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
        background: rgb(255, 255, 255);
        box-shadow: 1px 0px 10px 2px rgba(73, 80, 102, 0.213);
        padding: 2%;
        padding-left: 2%;
        border-radius: 10px;
        color: #2a3863;

        .bank-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            span{
                font-weight: 500;
                font-size: 1em;
            }
    
            button{
                padding: 14px 50px;
                background-color: #37ae9e;
                color: white;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: background-color 0.3s;
                box-shadow: 1px 0px 10px 2px rgba(73, 80, 102, 0.213);
    
                font-size: 1em;
    
                &:hover {
                    background-color: #1a6157;
                }
            }
        }

        form {
            display: flex;
            flex-direction: column;
            margin-top: 5vh;

            label {
                margin-bottom: 5px;
                font-weight: bold;
            }
    
            input {
                margin-bottom: 15px;
                padding: 10px;
                border: 1px solid #ddd;
                border-radius: 5px;
            }
        }
        .button-container {
            display: flex;
            justify-content: flex-end;
        }

        .button {
            padding: 15px 40px;
            background-color: #3bae37;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
            box-shadow: 1px 0px 10px 2px rgba(73, 80, 102, 0.213);
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 1em;
            &:hover {
                background-color: #1a6157;
            }
        }
    }
}