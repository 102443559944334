@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// Renk paleti

.sendmoney-container {
  padding: 2%;
  padding-top: 4%;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sendmoney-area {
  width: auto;
  height: auto;
  background-color: var(--card-background); // Kart Arka Plan Rengi
  box-shadow: 1px 0px 10px 2px var(--shadow-color);
  padding: 2%;
  padding-left: 2%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;


}
.send-header {
  font-size: 1em;
  color: var(--text-color);
  margin-bottom: 20px;
}
.contentitems-sendmoney {
  padding-bottom: 28px;
  border-radius: 10px;
  color: #2a3863;
  box-sizing: border-box;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
padding-bottom:5px;
  .amount {
    display: flex;
    align-items: center;
    gap: 10px;

    input {
      flex: 1;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
  }

  .button {
    padding: 10px 60px;
    background-color: var(--hover-color);

    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 1px 0px 10px 2px var(--color-border);

    &:hover {
      background-color: var(--button-color);
    }
  }
}

form {
  display: flex;
  flex-direction: column;
  padding: 0%;
  align-items: flex-start;
  label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 15px;
  }

  input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
}

.amount {
  position: relative;
  height: auto;
  width: 100%;
}

.amount-input {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 0.7em;
}

.currency-select {
  position: absolute;
  right: 10px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.success-message {
  color: var(--success-color);
  margin-bottom: 10px;
}

.error-message {
  color: var(--error-color);
  margin-bottom: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: var(--modal-background-color);
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  width: 20%;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.modal-buttons .button,
.button-accept {
  padding: 10px 20px;
  background-color: var(--modal-button-background);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 30%;
}

.button-accept {
  background-color: var(--modal-accept-button);
}

.modal-buttons .button:hover {
  background-color: var(--modal-button-hover);
}

.animation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
