
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.deposit-container {
  padding: 2%;
  padding-top: 4%;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.deposit-area {
  width: auto;
  height: auto;
  background-color: var(--card-background); // Kart Arka Plan Rengi
  box-shadow: 1px 0px 10px 2px var(--color-border);
  padding: 2%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  .send-header {
    font-size: 1.1em;
    color: var(--color-text);
  }
}

.option-tabs-area {
  margin-top: 1vh;
  margin-bottom: 5vh;

  .tabs {
    display: flex;
    gap: 40px;

    button {
      font-family: 'Poppins', sans-serif;
      border: 0;
      box-shadow: 1px 0px 10px 2px var(--color-border);
      width: 240px;
      padding: 19px; /* Ayarlanabilir padding */
      font-size: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 230px;
      position: relative;
      border-radius: 10px;
      font-weight: 700;

      .method-name {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
      }

      .deposit-text {
        cursor: pointer;
        font-weight: 500;
        font-size: 0.68em;
        margin-top: auto;
        width: 64%;
        padding: 9px;
        box-shadow: 1px 6px 10px 2px var(--color-border);
        border: 1.4px solid rgba(133, 133, 133, 0.707);
        border-radius: 20px;
        background-color: var(--color-input-background);
        color: rgb(90, 90, 90);
        line-height: 19px;
      }
    }
  }

  .content-deposit {
    padding-top: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 0%;
    align-items: flex-start;

    label {
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 0.9em;
    }

    input {
      margin-bottom: 15px;
      padding: 10px;
      border: 1px solid var(--color-input-border);
      border-radius: 5px;
    }
  }

  .amount {
    position: relative;
    height: auto;
    width: 100%;
  }

  .amount-input {
    position: relative;
    font-family: 'Poppins', sans-serif;
font-size: 0.7em;
  }

  .currency-select {
    position: absolute;
    right: 10px;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }

  .modal-api-data {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-overlay);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content-api {
    background-color: white;
    padding: 20px;
    padding-bottom: 40px;
    border-radius: 15px;
    text-align: center;
    width: 50%;
    text-align: left;
  }

  .result-header {
    color: #414141;
    font-size: 13px;
    margin-bottom: 0;
  }

  .result-info {
    color: #000000;
    font-size: 23px;
    margin-top: 0;
  }

  .modal-content-api p:first-child {
    font-size: 1.3em;
    color: #2a3863;
    font-weight: 500;
  }

  .contentitems-deposit {
    box-shadow: 1px 6px 10px 2px var(--color-border);
    padding-top: 4px;
    padding-bottom: 28px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    color: #2a3863;
    box-sizing: border-box;
    .iframe-container {
      margin-top: 20px;
      border: 1px solid #ccc;
      border-radius: 10px;
      overflow: hidden;
      width: 100%;
      height: 500px;
    }
    
    h2 {
      font-size: 1.2em;
      font-weight: 600;
      margin-bottom: 2.4vh;
    }

    .amount {
      display: flex;
      align-items: center;
      gap: 10px;

      input {
        flex: 1;
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      align-self: flex-end;
    }

    .button {
      padding: 10px 60px;
      background-color: var(--hover-color);

      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
      box-shadow: 1px 0px 10px 2px var(--color-border);

      &:hover {
        background-color: var(--button-color);
      }
    }
  }
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.button-send {
  padding: 15px 15px;
  background-color: var(--color-positive);
  color: #fff;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-cancel {
  background-color: var(--color-negative);
  padding: 15px 15px;
  color: #fff;
  border: none;
  border-radius: 55px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button-send:hover {
  background-color: #218838;
}

.input-account {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid var(--color-input-border);
  border-radius: 5px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  width: 20%;
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: auto;
}

.modal-buttons .button-no,
.button-accept {
  background-color: #ce0000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 30px;
}

.modal-buttons .button-accept {
  background-color: #434343;
}

.modal-buttons .button:hover {
  background-color: #0056b3;
}

.animation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay);
  display: flex;
  justify-content: center;
  align-items: center;
}
