@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');



.operation-container {
    padding: 2%;
    padding-top: 4%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sendmoney-area {
    box-shadow: 0 4px 20px var(--shadow-color);
    border-radius: 15px;
    padding: 2%;
    background-color: var(--card-background); // Kart Arka Plan Rengi
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;

    span {
        font-size: 1.5em;
        color: var(--text-color);
        margin-bottom: 20px;
    }

    .filter-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        gap: 1rem;
        margin-top: 1vh;
    }

    .selects {
        display: flex;
        flex-direction: column;
        gap: 10px;

        label {
            font-size: 1rem;
            font-weight: 600;
            color: var(--primary-color);
        }

        select {
            width: 300px;
            height: 40px;
            font-size: 1rem;
            border: 1px solid var(--input-border-color);
            border-radius: 8px;
            background: #ffffff;
            transition: all 0.3s ease;
            color: #343434;

            &:hover {
                border-color: var(--input-hover-border-color);
                box-shadow: 0 2px 10px var(--shadow-color);
            }
        }
    }

    .button-group {
        display: flex;
        gap: 1rem;
        margin-top: 4vh;
        margin-left: 2vw;
    }

    .filter-button {
        background-color: var(--button-color);
    }

    .clear-button {
        background-color: var(--danger-color);
    }

    .filter-button,
    .clear-button {
        color: white;
        padding: 0.8rem 2rem;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1rem;
        transition: all 0.3s ease;
        box-shadow: 0 4px 10px var(--shadow-color);

        &:hover {
            transform: translateY(-3px);
            opacity: 0.9;
            box-shadow: 0 6px 15px var(--shadow-color);
        }

        &:active {
            transform: translateY(0);
        }
    }

    .transaction-table {
        margin-top: 5vh;
        width: 100%;
        border-radius: 15px;
        overflow: hidden;

        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            background: var(--table-bg-color);
            box-shadow: 0 4px 20px var(--shadow-color);
        }

        th, td {
            text-align: left;
            color: var(--primary-color);
            border-bottom: 1px solid #e0e6ed;
            padding: 13px 13px !important;
            width: auto;
            font-size: 0.85em;
        }

        th {
            background: var(--button-color);
            color: var(--hover-color);
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }

        tr {
            transition: background-color 0.3s ease;
        }

        tr:hover {
            background-color: var(--hover-bg-color)
        }

        tr:nth-child(even) {
            background-color: var(--background-color);
        }

        tr:last-child td {
            border-bottom: none;
        }
    }

    .no-transactions {
        text-align: center;
        font-size: 1.2em;
        color: #777;
        margin-top: 20px;
        padding: 20px;
        background: var(--no-transactions-bg);
        border-radius: 15px;
    }
}
