body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg-color: #ffffff;
  --primary-color: #000367;
  --secondary-color: #5d9599;
  --highlight-color: #1a232d;
  --hover-bg-color: #a1a7ad;
  --hover-text-color: #ffffff;
  --divider-color: #57606c;
  --wallet-text-color: white;
  --menu-active-bg-color: #1a232d;
  --menu-active-text-color: #ffffff;
  --arrow-color: white;
  --hover-color: #B7C1E2;
  --text-color: #454545;
  --background-color: #fafafa;
  --primary-hover: #000367;
  --secondary-background: #fafafa;
  --text-dark: #454545;
  --text-light: #696969;
  --error-color: #ff4d4d;
  --link-color: #007bff;
  --reserved-color: #bababa;
  --light-blue: #9BB8CD;
  --color-secondary: #5d1654;
  --color-tertiary: #ff7a00;
  --color-accent: #fd2b38;
  --color-background: #ffffff;
  --color-text-primary: #505050;
  --color-text-secondary: #888;
  --color-positive: green;
  --color-negative: red;
  --color-hover: #5d9599;
  --color-text: #333333;
  --color-text-secondary: #7a7a7a;
  --color-positive-alt: #37ae9e;
  --color-negative-alt: #dc3545;
  --color-border: rgba(73, 80, 102, 0.213);
  --color-overlay: rgba(0, 0, 0, 0.5);
  --color-success-background: #d1e7dd;
  --color-success-text: #198754;
  --color-input-border: #ddd;
  --color-input-background: rgba(226, 226, 226, 0.762);
  --card-background: #fafbff;

  --divider-color-alt: #e6e6e6;
  --card-shadow: rgba(0, 0, 0, 0.2);
  --secondary-color-alt: #AFEEEE;
  --button-color: #000367;
  --danger-color: #bf0000;
  --input-border-color: #ddd;
  --input-hover-border-color: #888;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --highlight-color-alt: #f1f1f1;
  --table-bg-color: #ffffff;
  --no-transactions-bg: #f4f4f4;
  --primary-color-hover: #1a6157;
  --modal-background-color: white;
  --modal-button-background: #434343;
  --modal-button-hover: #0056b3;
  --modal-accept-button: #ce0000;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray-light: #f5f5f5;
  --color-gray-dark: #ddd;
  --color-primary: #37ae9e;
  --color-primary-dark: #1a6157;
  --color-shadow-alt: rgba(73, 80, 102, 0.213);
  --color-text-dark: #2a3863;
  --color-text-light-alt: #333;

  --color-circle-primary: #000367;
}
