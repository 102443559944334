@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');



.withdraw-container {
    padding: 2%;
    padding-top: 4%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.withdraw-area {
    width: auto;
    height: auto;
    background: var(--color-white);
    box-shadow: 1px 0px 10px 2px var(--color-shadow);
    padding: 2%;
    padding-left: 2%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;

    .withdraw-header {
        font-size: 1.1em;
        color: var(--color-text);
      }
}


.option-tabs-area-with {
    margin-top: 1vh;

    .tabs-with {
        display: flex;
        gap: 40px;

        .tab-button-with {
            font-family: 'Poppins', sans-serif;
            border: 0;
            box-shadow: 1px 0px 10px 2px var(--color-shadow);
            width: 240px;
            padding: 19px; // Adjust padding as needed
            font-size: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 230px; // Set a specific height to ensure space for alignment
            position: relative;
            border-radius: 10px;
            font-weight: 700;
        }

        .method-name-with {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--color-white);
        }

        .with-text {
            cursor: pointer;
            font-weight: 500;
            font-size: 0.4em;
            margin-top: auto;
            width: 60%;
            padding: 8px;
            box-shadow: 1px 6px 10px 2px var(--color-shadow);
            border: 1.4px solid rgba(133, 133, 133, 0.707); // Adds a black border
            border-radius: 20px;
            background-color: rgba(226, 226, 226, 0.762);
            color: rgb(90, 90, 90);
            line-height: 19px;
        }
    }

    .content {
        padding-top: 20px;
    }

    form {
        display: flex;
        flex-direction: column;
        padding: 0%;
        align-items: flex-start;

        label {
            margin-bottom: 5px;
            font-weight: 600;
            font-size: 15px;
        }

        input {
            margin-bottom: 15px;
            padding: 10px;
            border: 1px solid var(--color-gray-dark);
            border-radius: 5px;
        }
    }

    .amount {
        position: relative;
        height: auto;
        width: 100%;
    }

    .amount-input {
        position: relative;
    }

    .currency-select {
        position: absolute;
        right: 10px;
        top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
    }
}

.swiftcode-select-container {
    width: 100%;
}

.swiftcode-select {
    width: 100%;
    padding: 8px;
    font-size: 0.7em;
    border-radius: 4px;
    margin-bottom: 15px;
    padding: 10px 5px 10px 5px;
    border: 1px solid var(--color-gray-dark);
    border-radius: 5px;
}


.contentitems {
    box-shadow: 1px 6px 10px var(--color-shadow);
    padding: 4px 20px 28px 20px;
    border-radius: 10px;
    color: var(--color-text-dark);

    h2 {
        font-size: 1em;
        font-weight: 500;
        margin-bottom: 6vh;
    }

    .amount {
        display: flex;
        align-items: center;
        gap: 10px;

        input {
            flex: 1;
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        align-self: flex-end;
    }

    .button {
        padding: 10px 60px;
        background-color: var(--hover-color);

        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        box-shadow: 1px 0px 10px 2px var(--color-border);

        &:hover {
            background-color: var(--button-color);
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: var(--color-white);
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    width: 20%;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.modal-buttons .button,
.button-accept {
    padding: 10px 20px;
    background-color: var(--color-black);
    color: var(--color-white);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 30%;
}

.button-accept {
    background-color: var(--color-error);
}

.modal-buttons .button:hover {
    background-color: #0056b3;
}

.animation-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.swiftcode-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.swiftcode-card {
    background-color: var(--color-gray-light);
    border: 1px solid var(--color-gray-dark);
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    width: 128px;
    /* Kart genişliği */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}

.swiftcode-card:hover {
    background-color: var(--color-gray-dark);
}

.swiftcode-card.selected {
    border-color: #4caf50;
    background-color: #e8f5e9;
}