nav {
    width: auto;
    height: 70px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 20px;
  }
  .icon{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 20px;
  }
  nav .menu {
    display: flex;
    align-items: center;

    color: black;
  }
  
  nav .menu div {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  nav .menu .dropdown-content {
    display: block;
    position: absolute;
    top:28px;
    right: -20px;
    background-color: black;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    overflow: hidden;
    border-radius: 5px;
    padding: 10px;
  }
  nav .menu .dropdown-content div{
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: flex;
    min-width: 150px;
    width: 100%;
    gap: 10px;
    padding: 5px;

  }
  nav .menu .dropdown-content div:hover {
    background-color: #f1f1f1;
    overflow: hidden;
  }
  
  nav .menu .dropdown:hover .dropdown-content {
    display: block;

  }
  