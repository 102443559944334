

.transaction-header h3 {
    margin: 0;
    color: #333;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search-container .search-icon {
    position: absolute;
    right: 10px;
    color: #aaa;
}

.search-container input {
    width: 140px;
}

.search-container input[type="text"] {
    padding: 13px 10px 13px 10px;
    /* Add left padding to accommodate the icon */
    border-radius: 17px;
    border: 0px solid #ccc;
    font-size: 0.7em;
    background-color: #eeeeee;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}


.pagination {
    display: flex;
    justify-content: end;
    margin-top: 20px;

    .pagination-arrow {
        border: none;
        background: none;
        cursor: pointer;
        color: #000;
        font-size: 18px;
        padding: 5px 10px;

        &:disabled {
            color: #ccc;
            cursor: not-allowed;
        }
    }

    .pagination-button {
        border: none;
        background:  --color-light-background;
        cursor: pointer;
        margin: 0 5px;
        padding: 10px 15px;
        border-radius: 13px;
        color: #000;
        font-size: 16px;
        font-weight: bold;
        transition: background 0.3s;

        &.active {
            background: #000;
            color: #fff;
        }

        &:hover:not(.active) {
            background: #e0e0e0;
        }

    }
}


.account-container {
    padding: 2%;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: auto;
    height: auto;

    .accHeader {
        font-size: 1.3em;
    }
}

.add-button {
    margin-top: 20px;
    background-color: var(--button-color);
    color: white;
    border: none;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: var(--color-hover);
    }
}

.account-card-area {
    background-color: var(--card-background); // Kart Arka Plan Rengi
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 50%;
    max-width: 330px;
    position: relative;
}

.card-account {
    border-radius: 20px;
    position: relative;
    color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 99999;
    width: 100%;
}

.card-section {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 20px;
}

.card-section-left {
    background-color: #000367;
    clip-path: polygon(0% 0%, 100% 0, 100% 100%, 0% 100%);
}

.card-section-middle {
    background-color:#462FA5;
    clip-path: polygon(0% 50%, 100% 0, 100% 100%, 60% 100%);
}

.card-section-right {
    background-color:#B7C1E2;
    clip-path: polygon(0% 100%, 100% 0, 100% 100%, 60% 100%);
}

.card-account-header,
.card-account-number,
.card-account-balance,
.card-account-footer {
    position: relative;
    z-index: 1;
    padding: 15px 20px;
}

.card-account-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 20px;
}

.card-account-brand {
    font-size: 1.2em;
    font-weight: 900;
}

.card-brand {
    font-size: 1.2em;
    font-weight: 900;
    font-style: italic;
}

.card-account-number {
    display: flex;
    justify-content: space-between;
}

.card-account-balance {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.card-account-balance .currency {
    font-size: 1em;
    margin-right: 5px;
}

.card-account-balance .amount {
    font-size: 1.5em;
    font-weight: bold;
}

.card-account-footer {
    text-align: right;
    display: flex;
    flex-direction: column;
}

.card-type {
    font-size: 0.7em;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card-type::before {
    content: url('../../../assets/master.png');
    display: inline-block;
}

.account-button {
    background-color: #ce0000;
    border: none;
    color: white;
    width: 30%;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow 0.3s ease;
    box-shadow: 1px 0px 10px 2px rgba(46, 3, 3, 0.423);
}

.account-button:hover {
    background-color: #ce0000;
    box-shadow: 1px 0px 10px 2px rgba(69, 6, 6, 0.728);
}

.summary-dashboard-container {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    gap: 10px;
}

.account-summary,
.dashboard-container {
    flex: 1;
}

.dashboard-container {
    background-color: var(--card-background); // Kart Arka Plan Rengi
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wallet-info {
    .accAddress {
        font-weight: 600;
        display: flex;
        align-items: center;

        .copy-icon {
            margin-left: 10px;
            width: 15;
        }
    }

    .info-area {
        background-color: var(--card-background); // Kart Arka Plan Rengi
        padding: 1%;
        border-radius: 10px;
        margin-top: 20px;
        width: 50%;

        .total-assets {
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-weight: 500;
        }

        .label-and-icon {
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .arrow-icon {
            color: red;
            background-color: #f6e4e4;
            padding: 0.1%;
            border-radius: 4px;
        }
    }
}

.account-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin: 0 auto;
}

.transaction-details {
    background-color: var(--card-background); // Kart Arka Plan Rengi
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    padding-bottom: 40px;

    h3 {
        text-align: left;
    }
}

table {
    width: 100%;
    color: var(--color-text-primary);
    text-align: left;

    thead,
    tbody {
        width: auto;
    }

    td,
    th {
        width: 34px;
        font-size: 15px;
    }
}

.balance-card {
    background-color: var(--card-background); // Kart Arka Plan Rengi
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    height: 260px;
}

.balance-card-header {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
    width: 100%;

    span {
        padding: 20px;
        text-align: left;
    }
}

.balance-card-summary {
    display: flex;
    justify-content: space-around;
}

.deposits,
.withdrawals {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    font-size: 0.8em;
    color: var(--color-text-secondary);
}

.amount {
    font-size: 1em;
}

.balance-card-main {
    font-size: 2.9em;
    font-weight: 600;
    margin-bottom: 20px;

    .currency {
        font-size: 0.7em;
    }
}

.balance-card-actions {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.btn-deposit,
.btn-withdraw {
    background-color: #f0f0f0;
    border: none;
    border-radius: 30px;
    padding: 14px 60px;
    font-size: 1em;
    cursor: pointer;
}

.btn-deposit {
    background: #8F19A6;
    color: white;
}

.btn-withdraw {
    background: #B7C1E2;
    color: #333;
}

.transaction-history-container {
    background-color: var(--card-background); // Kart Arka Plan Rengi

    .transaction-header {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        margin-bottom: 20px;

    }

    .transaction-list {
        padding: 20px;

        
        &.empty {
            text-align: center;
            color: var(--color-text-secondary);
        }

        li {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            border-bottom: 1px solid var(--color-text-secondary);
        }

        .transaction-item {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px 0;

            &.positive {
                color: var(--color-positive);
            }

            &.negative {
                color: var(--color-negative);
            }
        }
    }
}

.line-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    height: 300px;
}