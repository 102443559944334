.sidebar-container {
    width: 14vw;
    background-color: #fafbff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5%;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
    header {
        width: auto;
        font-size: 1.4em;
        color: var(--primary-color);
        font-weight: 900;
        font-style: italic;

        span {
            color: var(--hover-color);
        }
    }

    img {
        margin-top: 3vh;
        margin-bottom: 3vh;
        display: flex;
        align-items: center;
        width: auto;
        text-align: center;
    }

    span {
        color: black;
    }

    .welcometext {
        font-size: 1em;
        margin-bottom: 0.5vh;
    }

    .username {
        font-size: 0.8em;
        word-wrap: normal;
    }

    .balance {
        gap: 20px;
        color: var(--wallet-text-color);
        display: flex;
        align-items: center;
        font-size: 0.8em;
    }

    .walletnum {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 10px;
        text-align: center;
        font-size: 0.86em;

        span {
            white-space: normal;
            word-break: break-all;
            color: var(--wallet-text-color);
        }
    }

    .sidebar-menu {
        display: flex;
        width: 100%;
        height: 100%;
        margin-top: 40%;
        flex-direction: column;
        justify-content: space-between;
    }

    .sidebar-items {
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 14px;
    }

    .sidebar-items li {
        margin-bottom: 10%;
        display: flex;
        align-items: center;
        padding: 15px;
        position: relative;
        padding-right: 20px;
        color: black;
    }

    .sidebar-items li:hover {
        background-color:  var(--hover-color);
        border-radius: 20px;
        color: var(--hover-text-color);
        width: 90%;
    }

    .sidebar-items li.active {
        background: linear-gradient(90deg,
                #000367 0%,
                #0344a4 50%,
                #83259f 100%);
        border-radius: 20px;
        color: var(--menu-active-text-color);
        width: 100%;    
    }

    .sidebar-items li.active span,
    .sidebar-items li.active svg {
        color: var(--menu-active-text-color) !important;
    }

    .sidebar-items li:hover span,
    .sidebar-items li:hover svg {
        color: var(--hover-text-color) !important;
    }

    .sidebar-items li:hover::after {
        content: '\203A';
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.9em;
        color: var(--arrow-color);
    }

    .sidebar-items span {
        margin-left: 14px;
    }

    .sidebar-signout {
        list-style-type: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
    }

    .sidebar-signout li {
        margin-bottom: 10%;
        display: flex;
        align-items: center;
        padding: 15px;
        position: relative;
        padding-right: 20px;
        color: #bf0000;
    }

    .sidebar-signout span {
        margin-left: 14px;
        color: #bf0000;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;

    }

    .divider {
        width: 100%;
        height: 1px;
        background-color: var(--color-circle-primary);
        justify-content: end;
    }
}