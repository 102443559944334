@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


.login-container {
    display: flex;
    height: 100vh;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    background: url("../../assets/icon.png") repeat;
    background-size: 15px; // Size of the icon
    background-color: rgba(184, 194, 227, 0.23); 

    .circle {
        position: absolute;
        border-radius: 50%;
        background: var(--color-circle-primary);
        opacity: 1;
        animation: pulse 6s infinite ease-in-out;
      
        &:nth-child(1) {
          width: 300px;
          height: 300px;
          top: 69%;
          left: -5%;
          background: var(--color-circle-primary);
          animation: pulse 8s infinite ease-in-out;
        }
      
        &:nth-child(2) {
          width: 150px;
          height: 150px;
          top: 1%;
          left: 25%;
          background: linear-gradient(
            135deg,
            #83259f 0%,
            #0344a4 50%,
            #00096c 100%
          );
          animation:  pulse 8s infinite ease-in-out;
        }
      
        &:nth-child(3) {
          width: 150px;
          height: 150px;
          top: 6%;
          right: 15%;
          background: var(--color-circle-primary);
          animation:  pulse 8s infinite ease-in-out;
        }
      
        &:nth-child(4) {
          width: 250px;
          height: 250px;
          top: 10%;
          right: 1%;
          background: linear-gradient(
            90deg,
            #000367 0%,
            #0344a4 50%,
            #83259f 100%
          );
          animation: pulse 8s infinite ease-in-out;
        }
      
        &:nth-child(5) {
          width: 90px;
          height: 90px;
          bottom: 6%;
          right: 27%;
          background: var(--color-circle-primary);
          animation:  pulse 8s infinite ease-in-out;
        }
      }
      
      /* Animation Keyframes */
      @keyframes pulse {
        0%, 100% {
          transform: scale(1);
          opacity: 1;
        }
        50% {
          transform: scale(1.1);
          opacity: 0.8;
        }
      }
      
      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      
      @keyframes moveUpDown {
        0%, 100% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-20px);
        }
      }
      
      @keyframes scale {
        0%, 100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.2);
        }
      }
      
    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px;
        width: 24%;
        background-color: #fafbff;
        border-radius: 20px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);

        .loginheader {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 2vh;

            span {
                font-weight: 600;
                margin-top: 1vh;
            }
        }

        .language-section {
            position: absolute;
            top: 20px;
            right: 20px;

            h5 {
                margin: 0;
            }
        }

        h5 {
            color: var(--text-color);
            font-weight: 500;
            margin-top: 6vh;
            font-size: 0.6em;
        }

        .url-div {
            display: flex;
            align-items: center;
            border: 1px solid #bababa;
            border-radius: 20px;
            padding: 1.7%;
            margin: 2vh 0;

            span {
                display: flex;
                align-items: center;
                font-size: 0.6em;
                color: #373737;

                .icon {
                    margin-right: 0;
                    color: #138034;
                }

                .divider {
                    height: 13px;
                    width: 1px;
                    background-color: #138034;
                    margin: 5px;
                }
            }
        }

        .urlnote {
            color: var(--text-color);
            font-weight: 500;
            font-size: 0.6em;
            margin-bottom: 1vh;
        }

        form {
            width: 100%;
            margin-top: 2vh;
            display: flex;
            flex-direction: column;
            align-items: center;

            .form-group {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                justify-content: center;

                input {
                    padding: 10px;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    font-family: 'Poppins', sans-serif;
                    width: 100%;

                    &:focus {
                        outline: none;
                        border-color: #bd0000;
                    }
                }
            }

            .button {
                width: 100%;
                padding: 10px;
                background-color: var(--primary-color);
                color: #fff;
                font-weight: 700;
                border: 0;
                border-radius: 4px;
                margin-top: 2vh;
                font-family: 'Poppins', sans-serif;
                transition: background-color 0.3s ease;

                &:hover {
                    background-color: var(--primary-hover);
                }
            }
        }

        .p {
            text-align: center;
            margin-top: 1vh;

            p {
                margin-bottom: -4px;
                font-size: 0.8em;
                color: #000;
            }

            p:nth-child(2) {
                color: #696969;

                a {
                    color: var(--primary-color);
                    text-decoration: underline;
                }
            }

            p:nth-child(3) {
                color: #9BB8CD;
            }
        }

        .footer {
            width: 100%;
            margin-top: 7vh;
        }

        .divider {
            width: 100%;
            border: 0.5px solid #e6e6e6;
            margin: 2vh 0;
        }

        .rezerved {
            display: flex;
            justify-content: center;
            font-size: 0.7em;
            color: #bababa;

            a {
                color: #007bff;
                text-decoration: none;
                margin-right: 5px;
            }
        }
    }
}

.error-message {
    color: red;
    margin-top: 10px;
    font-size: 0.9em;
}
